import React from 'react'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'

import MyLogo from './logo.svg'
import Hamburger from '../components/Hamburger'
import '../styles/application.scss'

class TopMenuItem extends React.Component {
  onClick = (e) => {
    this.props.onChange(this.props.item.url)
    e.stopPropagation()
    return false
  }

  render() {
    const { title, url } = this.props.item

    let clsName = 'menu__item'

    if (this.props.selected) {
      clsName += ' menu__item--current'
    }

    if (title === 'Home') {
      clsName += ' hide-for-medium-only'
    }

    return (
      <li className={clsName}>
        <Link className="menu__link" to={url} onClick={this.onClick}>
          {title}
        </Link>
      </li>
    )
  }
}

class TopMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = { currentItem: props.location.pathname }
  }

  onChange = (url) => {
    this.setState({ currentItem: url })
  }

  render() {
    const items = [
      { title: 'Home', url: '/' },
      { title: 'Our Work', url: '/work' },
      { title: 'Blog', url: '/blog' },
      { title: 'Services', url: '/services' },
      { title: 'Contact', url: '/contact' },
    ]

    const selected = this.state.currentItem

    return (
      <nav className="menu menu--prospero medium-right">
        <ul className="menu__list">
          {items.map((item, i) => (
            <TopMenuItem
              item={item}
              key={i}
              onChange={this.onChange}
              selected={item.url === selected}
            />
          ))}
        </ul>
      </nav>
    )
  }
}

class MobileMenu extends React.Component {
  constructor() {
    super()

    this.state = {
      visible: false,
    }
  }

  toggleMenu = () => {
    this.setState({
      visible: !this.state.visible,
    })
  }

  hide = () => {
    this.setState({
      visible: false,
    })
  }

  mobileMenu() {
    return (
      <div
        className={'mobile-menu-popup' + (this.state.visible ? ' visible' : '')}
      >
        <div className="content">
          <ul className="menu vertical">
            <li className="menu__item menu__item--current">
              <Link className="menu__link" to="/" onClick={this.hide}>
                Home
              </Link>
            </li>
            <li className="menu__item">
              <Link className="menu__link" to="/work" onClick={this.hide}>
                Our Works
              </Link>
            </li>
            <li className="menu__item">
              <Link className="menu__link" to="/blog" onClick={this.hide}>
                Blog
              </Link>
            </li>
            <li className="menu__item">
              <Link className="menu__link" to="/services" onClick={this.hide}>
                Services
              </Link>
            </li>
            <li className="menu__item">
              <Link className="menu__link" to="/contact" onClick={this.hide}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    )
  }

  topBar() {
    const { visible } = this.state

    return (
      <div className="row mobile-menu show-for-small-only">
        <div style={{ width: '100%' }}>
          <a href="/" className="logo">
            <img src={MyLogo} style={{ maxWidth: '200px' }} />
          </a>
          <a className="menu-btn" style={{ float: 'right', display: 'block' }}>
            <Hamburger checked={visible} onClick={this.toggleMenu} />
          </a>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.topBar()}
        {this.mobileMenu()}
      </div>
    )
  }
}

class Template extends React.Component {
  isHomePage() {
    const { location } = this.props
    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return location?.pathname === rootPath
  }

  header() {
    let theLogo = null

    if (this.isHomePage()) {
      theLogo = <img src={MyLogo} />
    } else {
      theLogo = (
        <Link
          style={{
            boxShadow: 'none',
            textDecoration: 'none',
            color: 'inherit',
          }}
          to={'/'}
        >
          <img src={MyLogo} />
        </Link>
      )
    }

    return (
      <div className="grid-x top-menu hide-for-small-only align-justify">
        <div className="cell medium-4 large-3">
          <div className="logo">{theLogo}</div>
        </div>
        <div className="cell medium-8 large-6">
          <TopMenu location={this.props.location} />
        </div>
      </div>
    )
  }

  footer() {
    return (
      <footer>
        <div className="grid-x">
          <div className="cell small-10 small-offset-1 medium-10 medium-offset-1 large-4 text-left">
            <div className="logo">
              <img src={MyLogo} />
            </div>
            <div className="about">
              Mobelop is a eCommerce agency focused on helping merchants succeed
              on the Magento platform.
            </div>
            <div className="contacts">
              <p>
                <label>
                  <i className="fi-mail"></i>
                </label>
                <a href="mailto:info@mobelop.com">info@mobelop.com</a>
              </p>
            </div>
          </div>
          <div className="cell small-10 small-offset-1 medium-5 medium-offset-1 large-2 large-offset-1 services small-line-break">
            <h4>Services</h4>
            <ul>
              <li>
                <Link to="/services#magento2">Magento 2 Platform</Link>
              </li>
              <li>
                <Link to="/services#integrations">Integrations</Link>
              </li>
              <li>
                <Link to="/services#migration">Update your website</Link>
              </li>
              <li>
                <Link to="/services#support">Support</Link>
              </li>
            </ul>
          </div>
          <div className="small-10 small-offset-1 medium-4 medium-offset-2 follow-us large-2 large-offset-1 small-line-break">
            <h4>Connect With Us</h4>
            <a
              href="https://facebook.com/mobelopcom/"
              className="social-ico"
              target="_blank"
            >
              <i className="fi-social-facebook"></i>
            </a>
            <a
              href="https://twitter.com/st4nsm"
              className="social-ico"
              target="_blank"
              style={{ marginLeft: '1rem' }}
            >
              <i className="fi-social-twitter"></i>
            </a>
          </div>
        </div>
      </footer>
    )
  }

  isInBlog() {
    const { location } = this.props
    let blogPath = `/blog/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      blogPath = __PATH_PREFIX__ + `/blog/`
    }

    return location?.pathname?.indexOf(blogPath) === 0
  }

  body() {
    const { children } = this.props

    if (this.isInBlog()) {
      return (
        <div>
          <div className="pagehead">
            <div className="pagehead__wrap black small" />
          </div>
          <div className="callout white">
            <div className="grid-x">
              <div className="cell small-10 small-offset-1 medium-8 medium-offset-2">
                {children}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return children
    }
  }

  render() {
    let header = this.header()

    if (typeof window !== 'undefined' && window.dataLayer !== undefined) {
      try {
        window.dataLayer.push({
          event: 'tl-loaded',
          virtualUrl: window.location.href,
        })
      } catch (e) {}
    }

    return (
      <div>
        <Helmet>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,300,500,600,700|Roboto:300,400,500"
          />
          <link rel="shortcut icon" href="/favicon.png" />
        </Helmet>
        {header}
        <MobileMenu />
        {this.body()}
        {this.footer()}
      </div>
    )
  }
}

export default Template
